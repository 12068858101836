/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:4c898582-c180-4f6b-8eea-30a68c781fcb",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_VpdLGorLA",
    "aws_user_pools_web_client_id": "3957smiv93ha3fkcsv7nvsphrk",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://jhai5wsygvbrbfu5qzr7yuyefu.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "predictions": {
        "convert": {
            "speechGenerator": {
                "region": "eu-west-2",
                "proxy": false,
                "defaults": {
                    "VoiceId": "Amy",
                    "LanguageCode": "en-GB"
                }
            }
        }
    }
};


export default awsmobile;
